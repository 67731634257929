import { Logo, LogoStar } from "./svg";
import { Link } from "react-router-dom";
require("./styles/footer.scss");

const Footer = () => {
  return (
    <footer>
      <div className="watermark">
        <LogoStar />
      </div>
      <div className="wrapper">
        <div className="branding">
          <Logo style="fullDark" />
        </div>
        <ul className="links ">
          <li>
            <h4>Menu</h4>
          </li>
          <li>
            <Link to="#">Explore</Link>
          </li>
          <li>
            <Link to="#">Loot box</Link>
          </li>
          <li>
            <Link to="#">Launchpad</Link>
          </li>
          <li>
            <Link to="#">Staking</Link>
          </li>
        </ul>
        <ul className="links ">
          <li>
            <h4>Community</h4>
          </li>
          <li>
            <Link to="#">Suggest feature</Link>
          </li>
          <li>
            <Link to="#">Help center</Link>
          </li>
        </ul>
        <ul className="links ">
          <li>
            <h4>Legal</h4>
          </li>
          <li>
            <Link to="#">Terms of use</Link>
          </li>
          <li>
            <Link to="#">Privacy policy</Link>
          </li>
          <li>
            <Link to="#">Cookie management</Link>
          </li>
        </ul>
        <ul className="links social">
          <li>
            <h4>Contact</h4>
          </li>
          <li className="social_icons">
            <a href="#">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 20C15.5242 20 20 15.5242 20 10C20 4.47583 15.5242 0 10 0C4.47583 0 0 4.47583 0 10C0 15.5242 4.47583 20 10 20ZM4.57583 9.78333L14.2175 6.06583C14.665 5.90417 15.0558 6.175 14.9108 6.85167L14.9117 6.85083L13.27 14.585C13.1483 15.1333 12.8225 15.2667 12.3667 15.0083L9.86667 13.1658L8.66083 14.3275C8.5275 14.4608 8.415 14.5733 8.15667 14.5733L8.33417 12.0292L12.9675 7.84333C13.1692 7.66583 12.9225 7.56583 12.6567 7.7425L6.93083 11.3475L4.4625 10.5775C3.92667 10.4075 3.915 10.0417 4.57583 9.78333Z"
                  fill="#242424"
                />
              </svg>
            </a>
            <a href="#">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM17 6.44609L16.0438 7.3625C15.9594 7.42578 15.9195 7.52891 15.9359 7.62969V14.3727C15.9195 14.4758 15.9594 14.5789 16.0438 14.6398L16.9813 15.5563V15.7602H12.275V15.5656L13.243 14.6258C13.3391 14.5297 13.3391 14.5016 13.3391 14.3586V8.90234L10.6437 15.7367H10.2805L7.14453 8.90234V13.4844C7.11641 13.6766 7.18437 13.8711 7.32031 14.0094L8.58125 15.5352V15.7391H5V15.5352L6.26094 14.0094C6.32737 13.9408 6.37681 13.8575 6.4053 13.7664C6.43379 13.6752 6.44054 13.5786 6.425 13.4844V8.1875C6.44141 8.03984 6.38516 7.89687 6.27266 7.79609L5.15234 6.44609V6.24219H8.63281L11.3187 12.1367L13.6836 6.24688H17V6.44609Z"
                  fill="black"
                />
              </svg>
            </a>
            <a href="#">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.1908 0H1.80916C0.81 0 0 0.81 0 1.80916V18.1908C0 19.19 0.81 20 1.80916 20H18.1908C19.19 20 20 19.19 20 18.1908V1.80916C20 0.81 19.19 0 18.1908 0ZM6.18885 17.2693C6.18885 17.5601 5.95314 17.7958 5.66236 17.7958H3.4212C3.13042 17.7958 2.89471 17.5601 2.89471 17.2693V7.8745C2.89471 7.58372 3.13042 7.34801 3.4212 7.34801H5.66236C5.95314 7.34801 6.18885 7.58372 6.18885 7.8745V17.2693ZM4.54178 6.46241C3.36592 6.46241 2.41267 5.50916 2.41267 4.3333C2.41267 3.15743 3.36592 2.20419 4.54178 2.20419C5.71764 2.20419 6.67089 3.15743 6.67089 4.3333C6.67089 5.50916 5.7177 6.46241 4.54178 6.46241ZM17.901 17.3117C17.901 17.5791 17.6843 17.7958 17.417 17.7958H15.012C14.7447 17.7958 14.528 17.5791 14.528 17.3117V12.905C14.528 12.2476 14.7208 10.0243 12.81 10.0243C11.3279 10.0243 11.0272 11.5461 10.9669 12.2291V17.3117C10.9669 17.5791 10.7502 17.7958 10.4828 17.7958H8.15681C7.88948 17.7958 7.67272 17.5791 7.67272 17.3117V7.83209C7.67272 7.56476 7.88948 7.34801 8.15681 7.34801H10.4828C10.7501 7.34801 10.9669 7.56476 10.9669 7.83209V8.65173C11.5164 7.82696 12.3332 7.19037 14.0722 7.19037C17.9231 7.19037 17.901 10.7881 17.901 12.7648V17.3117Z"
                  fill="#242424"
                />
              </svg>
            </a>
            <a href="#">
              <svg
                width="21"
                height="17"
                viewBox="0 0 21 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.9201 2.0001C20.1501 2.35012 19.32 2.58013 18.46 2.69014C19.34 2.16011 20.0201 1.32007 20.3401 0.310016C19.51 0.810042 18.59 1.16006 17.6199 1.36007C16.8299 0.500026 15.7198 0 14.4598 0C12.1096 0 10.1895 1.9201 10.1895 4.29022C10.1895 4.63024 10.2295 4.96025 10.2996 5.27027C6.73937 5.09026 3.56921 3.38017 1.4591 0.790041C1.08908 1.42007 0.879069 2.16011 0.879069 2.94015C0.879069 4.43023 1.62911 5.7503 2.78917 6.50033C2.07913 6.50033 1.4191 6.30032 0.839067 6.00031C0.839067 6.00031 0.839067 6.00031 0.839067 6.03031C0.839067 8.11042 2.31914 9.85051 4.27924 10.2405C3.91922 10.3405 3.53921 10.3905 3.14919 10.3905C2.87917 10.3905 2.60916 10.3605 2.34914 10.3105C2.88917 12.0006 4.45925 13.2607 6.34935 13.2907C4.88927 14.4507 3.03918 15.1308 1.01908 15.1308C0.679058 15.1308 0.339041 15.1108 -0.000976562 15.0708C1.89912 16.2908 4.15924 17.0009 6.57936 17.0009C14.4598 17.0009 18.79 10.4605 18.79 4.79025C18.79 4.60024 18.79 4.42023 18.78 4.23022C19.62 3.63019 20.3401 2.87015 20.9201 2.0001Z"
                  fill="#242424"
                />
              </svg>
            </a>
            <a href="#">
              <svg
                width="20"
                height="15"
                viewBox="0 0 20 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.5879 2.19117C19.3575 1.33435 18.6819 0.658937 17.8252 0.428224C16.2602 0 9.99977 0 9.99977 0C9.99977 0 3.73963 0 2.17456 0.411988C1.33435 0.642457 0.642335 1.33448 0.411866 2.19117C0 3.75611 0 7.0016 0 7.0016C0 7.0016 0 10.2634 0.411866 11.812C0.642579 12.6687 1.31787 13.3441 2.17469 13.5749C3.75611 14.0032 10 14.0032 10 14.0032C10 14.0032 16.2602 14.0032 17.8252 13.5912C18.682 13.3606 19.3575 12.6852 19.5882 11.8285C19.9999 10.2634 19.9999 7.01808 19.9999 7.01808C19.9999 7.01808 20.0164 3.75611 19.5879 2.19117ZM8.00661 9.9999V4.0033L13.2124 7.0016L8.00661 9.9999Z"
                  fill="#242424"
                />
              </svg>
            </a>
            <a href="#">
              <svg
                width="20"
                height="15"
                viewBox="0 0 20 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.5879 2.19117C19.3575 1.33435 18.6819 0.658937 17.8252 0.428224C16.2602 0 9.99977 0 9.99977 0C9.99977 0 3.73963 0 2.17456 0.411988C1.33435 0.642457 0.642335 1.33448 0.411866 2.19117C0 3.75611 0 7.0016 0 7.0016C0 7.0016 0 10.2634 0.411866 11.812C0.642579 12.6687 1.31787 13.3441 2.17469 13.5749C3.75611 14.0032 10 14.0032 10 14.0032C10 14.0032 16.2602 14.0032 17.8252 13.5912C18.682 13.3606 19.3575 12.6852 19.5882 11.8285C19.9999 10.2634 19.9999 7.01808 19.9999 7.01808C19.9999 7.01808 20.0164 3.75611 19.5879 2.19117ZM8.00661 9.9999V4.0033L13.2124 7.0016L8.00661 9.9999Z"
                  fill="#242424"
                />
              </svg>
            </a>
            <a href="#">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0001 6.87531C8.2798 6.87531 6.87589 8.27921 6.87589 9.99953C6.87589 11.7198 8.2798 13.1237 10.0001 13.1237C11.7204 13.1237 13.1243 11.7198 13.1243 9.99953C13.1243 8.27921 11.7204 6.87531 10.0001 6.87531ZM19.3704 9.99953C19.3704 8.70578 19.3821 7.42374 19.3095 6.13234C19.2368 4.63234 18.8946 3.30109 17.7978 2.20421C16.6985 1.10499 15.3696 0.76515 13.8696 0.692494C12.5759 0.619838 11.2939 0.631557 10.0025 0.631557C8.7087 0.631557 7.42667 0.619838 6.13527 0.692494C4.63527 0.76515 3.30402 1.10734 2.20714 2.20421C1.10792 3.30343 0.76808 4.63234 0.695424 6.13234C0.622767 7.42609 0.634486 8.70812 0.634486 9.99953C0.634486 11.2909 0.622767 12.5753 0.695424 13.8667C0.76808 15.3667 1.11027 16.698 2.20714 17.7948C3.30636 18.8941 4.63527 19.2339 6.13527 19.3066C7.42902 19.3792 8.71105 19.3675 10.0025 19.3675C11.2962 19.3675 12.5782 19.3792 13.8696 19.3066C15.3696 19.2339 16.7009 18.8917 17.7978 17.7948C18.897 16.6956 19.2368 15.3667 19.3095 13.8667C19.3845 12.5753 19.3704 11.2933 19.3704 9.99953ZM10.0001 14.8066C7.33995 14.8066 5.19308 12.6597 5.19308 9.99953C5.19308 7.33937 7.33995 5.19249 10.0001 5.19249C12.6603 5.19249 14.8071 7.33937 14.8071 9.99953C14.8071 12.6597 12.6603 14.8066 10.0001 14.8066ZM15.004 6.11827C14.3829 6.11827 13.8814 5.61671 13.8814 4.99562C13.8814 4.37453 14.3829 3.87296 15.004 3.87296C15.6251 3.87296 16.1267 4.37453 16.1267 4.99562C16.1269 5.1431 16.0979 5.28917 16.0416 5.42546C15.9852 5.56175 15.9026 5.68558 15.7983 5.78987C15.694 5.89415 15.5701 5.97684 15.4339 6.0332C15.2976 6.08955 15.1515 6.11846 15.004 6.11827Z"
                  fill="black"
                />
              </svg>
            </a>
            <a href="#">
              <svg
                width="24"
                height="22"
                viewBox="0 0 24 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 0.5C4.0365 0.5 0 3.8645 0 8C0 10.1855 1.116 12.2135 3.0855 13.6535L2.3295 15.1655C2.1885 15.446 2.238 15.7865 2.454 16.0145C2.598 16.1675 2.7975 16.25 3 16.25C3.099 16.25 3.1995 16.2305 3.2955 16.19L5.9475 15.0545C7.191 15.431 8.517 15.545 9.8475 15.4685C10.2615 15.4475 10.5795 15.0935 10.557 14.6795C10.5345 14.264 10.1415 13.925 9.768 13.9715C8.526 14.0345 7.293 13.925 6.147 13.5425C5.973 13.4855 5.784 13.4945 5.6145 13.565L4.587 14.006L4.722 13.7345C4.8945 13.3895 4.776 12.968 4.4475 12.764C2.574 11.594 1.5 9.8585 1.5 8C1.5 4.691 4.8645 2 9 2C13.1355 2 16.5 4.691 16.5 8C16.5 8.225 16.4895 8.4365 16.452 8.648C16.3785 9.056 16.6515 9.446 17.058 9.518C17.472 9.5945 17.8545 9.32 17.928 8.912C17.9805 8.6135 18 8.315 18 8C18 3.8645 13.9635 0.5 9 0.5Z"
                  fill="#242424"
                />
                <path
                  d="M21.4575 18.4865C23.061 17.3525 24 15.7175 24 14C24 10.691 20.6355 8 16.5 8C12.3645 8 9 10.691 9 14C9 17.6645 13.08 20.6225 17.856 19.8965L21.4545 21.44C21.549 21.479 21.651 21.5 21.75 21.5C21.9525 21.5 22.152 21.4175 22.296 21.263C22.5135 21.035 22.563 20.696 22.422 20.414L21.4575 18.4865ZM20.112 17.567C19.767 17.765 19.6365 18.197 19.8135 18.5525L20.163 19.2545L18.246 18.431C18.153 18.392 18.051 18.371 17.9505 18.371C17.907 18.371 17.865 18.374 17.823 18.3815C17.3625 18.4625 16.929 18.5 16.5 18.5C13.191 18.5 10.5 16.481 10.5 14C10.5 11.519 13.191 9.5 16.5 9.5C19.809 9.5 22.5 11.519 22.5 14C22.5 15.398 21.63 16.6985 20.112 17.567Z"
                  fill="#242424"
                />
                <path
                  d="M6.02996 5H6.01496C5.60096 5 5.27246 5.336 5.27246 5.75C5.27246 6.164 5.61596 6.5 6.02996 6.5C6.44396 6.5 6.77995 6.164 6.77995 5.75C6.77995 5.336 6.44396 5 6.02996 5Z"
                  fill="#242424"
                />
                <path
                  d="M12.03 5H12.015C11.601 5 11.2725 5.336 11.2725 5.75C11.2725 6.164 11.616 6.5 12.03 6.5C12.444 6.5 12.7799 6.164 12.7799 5.75C12.7799 5.336 12.444 5 12.03 5Z"
                  fill="#242424"
                />
                <path
                  d="M14.28 11.75H14.265C13.8495 11.75 13.5225 12.086 13.5225 12.5C13.5225 12.914 13.866 13.25 14.28 13.25C14.694 13.25 15.03 12.914 15.03 12.5C15.03 12.086 14.694 11.75 14.28 11.75Z"
                  fill="#242424"
                />
                <path
                  d="M18.78 11.75H18.765C18.351 11.75 18.0225 12.086 18.0225 12.5C18.0225 12.914 18.3675 13.25 18.78 13.25C19.194 13.25 19.53 12.914 19.53 12.5C19.53 12.086 19.194 11.75 18.78 11.75Z"
                  fill="#242424"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
